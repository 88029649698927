//
// Misc: Colors
//

// Background colors (theme colors)
@each $name, $color in $theme-colors {
  @include background-variant($name, $color);
}

// Background colors (colors)
@each $name, $color in $colors {
  @include background-variant($name, $color);
}

.bg-gray {
  color: color-contrast($gray-500);
  background-color: $gray-500;
}

.bg-gray-light {
  color: color-contrast(tint-color($gray-200, 3%)) !important;
  background-color: tint-color($gray-200, 3%);
}

.bg-black {
  color: color-contrast($black) !important;
  background-color: $black;
}

.bg-white {
  color: color-contrast($white) !important;
  background-color: $white;
}

// Backgrund Color Disabled
[class^="bg-"].disabled {
  opacity: .65;
}

// Text muted hover
a.text-muted:hover {
  color: $primary !important;
}

// Link Styles
.link-muted {
  color: shade-color($gray-500, 30%);

  &:hover,
  &:focus {
    color: shade-color($gray-500, 40%);
  }
}

.link-black {
  color: $gray-600;

  &:hover,
  &:focus {
    color: tint-color($gray-500, 20%);
  }
}

// // Accent colors (theme colors)
// @each $name, $color in $theme-colors {
//   @include accent-variant($name, $color);
// }

// // Accent colors (colors)
// @each $name, $color in $colors {
//   @include accent-variant($name, $color);
// }

// Accent button override fix
[class*="accent-"] {
  @each $name, $color in $theme-colors {
    a.btn-#{$name} {
      color: color-contrast($color);
    }
  }
}
