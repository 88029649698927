//
// Mixins: Direct Chat
//

// Direct Chat Variant
@mixin direct-chat-variant($bg-color, $color: $white) {
  .end > .direct-chat-text {
    color: color-contrast($bg-color);
    background-color: $bg-color;
    border-color: $bg-color;

    &::after,
    &::before {
      border-left-color: $bg-color;
    }
  }
}
