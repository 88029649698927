.sidebar {
  // min-height: 100%;
  padding-top: $lte-sidebar-padding-y;
  padding-right: $lte-sidebar-padding-x;
  padding-bottom: $lte-sidebar-padding-y;
  padding-left: $lte-sidebar-padding-x;
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollbar-color-gray();
  @include scrollbar-width-thin();
}
