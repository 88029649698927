//
// Core: Brand
//

// @include brand-variant($lte-sidebar-dark-bg, $lte-sidebar-dark-color);

.brand-container {
  display: grid;
  grid-template-areas: "brand-link pushmenu";
  justify-content: space-between;
  padding: $lte-brand-link-padding-y $lte-sidebar-padding-x;
  overflow: hidden;
  font-size: $navbar-brand-font-size;
  white-space: nowrap;
  transition: width $lte-transition-speed $lte-transition-fn;

  .brand-link {
    grid-area: brand-link;

    &:hover {
      text-decoration: none;
    }

    .brand-image {
      float: left;
      width: auto;
      max-height: 33px;
      margin-top: -3px;
      margin-right: .5rem;
      margin-left: .8rem;
      line-height: .8;
    }
  }

  .pushmenu {
    grid-area: pushmenu;
  }

  .sidebar-bg-dark & {
    border-bottom: $lte-brand-link-border-buttom solid $gray-700;

    .brand-link,
    .pushmenu {
      color: rgba($white, .8);

      &:hover {
        color: $white;
      }
    }
  }

  .sidebar-bg-light & {
    border-bottom: $lte-brand-link-border-buttom solid $gray-300;

    .brand-link,
    .pushmenu {
      color: rgba($black, .8);

      &:hover {
        color: $black;
      }
    }
  }
}

.sidebar-collapse:not(.sidebar-is-hover) {
  .brand-container {
    justify-content: center;

    .brand-link {
      width: 0;
    }
  }
}
