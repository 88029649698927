//
// Mixins: Backgrounds
//

// Background Variant
@mixin background-variant($name, $color) {
  .bg-#{$name} {
    background-color: #{$color} !important;

    &,
    > a {
      color: color-contrast($color) !important;
    }

    &.btn {
      &:hover {
        color: shade-color(color-contrast($color), 7.5%);
        border-color: shade-color($color, 10%);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &:active,
      &.active {
        color: color-contrast(shade-color($color, 10%));
        background-color: shade-color($color, 10%) !important;
        border-color: shade-color($color, 12.5%);
      }
    }
  }
}
