//
// Core: Main Footer
//

.main-footer {
  grid-area: main-footer;
  width: inherit;
  padding: $lte-main-footer-padding;
  color: $lte-main-footer-color;
  background-color: $lte-main-footer-bg;
  border-top: $lte-main-footer-border-top;

  // .fs-7 &,
  // &.fs-7 {
  //   padding: $lte-main-footer-padding-sm;
  // }
}

.layout-footer-fixed {
  .main-footer {
    position: fixed;
    bottom: 0;
  }

  .content-wrapper {
    margin-bottom: $lte-main-footer-height;
  }
}
