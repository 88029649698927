// Custom AdminLTE Variables

// COLORS
// --------------------------------------------------------
$lte-gray-x-light: #d2d6de !default;

// Dynamic Variables
// --------------------------------------------------------
:root {
  // Sidebar
  --lte-sidebar-width: 250px;

  // Sidebar active links
  --lte-sidebar-menu-active-bg: #{$primary};
  --lte-sidebar-menu-active-color: #{$white};

  // Dark Sidebar Native Variables
  --lte-sidebar-dark-bg: #343a40;
  --lte-sidebar-dark-hover-bg: rgba(255, 255, 255, .1);
  --lte-sidebar-dark-color: #c2c7d0;
  --lte-sidebar-dark-hover-color: #{$white};
  --lte-sidebar-dark-active-color: #{$white};
  --lte-sidebar-dark-submenu-bg: transparent;
  --lte-sidebar-dark-submenu-color: var(--lte-sidebar-dark-color);
  --lte-sidebar-dark-submenu-hover-color: #{$white};
  --lte-sidebar-dark-submenu-hover-bg: var(--lte-sidebar-dark-hover-bg);
  --lte-sidebar-dark-submenu-active-color: var(--lte-sidebar-dark-bg);
  --lte-sidebar-dark-submenu-active-bg: rgba(255, 255, 255, .9);
  --lte-sidebar-dark-header-color: #{tint-color(#c2c7d0, 5%)};

  // Light Sidebar Native Variables
  --lte-sidebar-light-bg: #{$white};
  --lte-sidebar-light-hover-bg: rgba(#{$black}, .1);
  --lte-sidebar-light-color: #{$gray-800};
  --lte-sidebar-light-hover-color: #{$gray-900};
  --lte-sidebar-light-active-color: #{$black};
  --lte-sidebar-light-submenu-bg: transparent;
  --lte-sidebar-light-submenu-color: #777;
  --lte-sidebar-light-submenu-hover-color: #{$black};
  --lte-sidebar-light-submenu-hover-bg: var(--lte-sidebar-light-hover-bg);
  --lte-sidebar-light-submenu-active-color: var(--lte-sidebar-light-hover-color);
  --lte-sidebar-light-submenu-active-bg: var(--lte-sidebar-light-submenu-hover-bg);
  --lte-sidebar-light-header-color: #{shade-color($gray-800, 5%)};
}

// LAYOUT
// --------------------------------------------------------

$lte-font-size-root:              1rem !default;

// TRANSITIONS SETTINGS
// --------------------------------------------------------

// Transition global options
$lte-transition-speed:            .3s !default;
$lte-transition-fn:               ease-in-out !default;

// Sidebar
// --------------------------------------------------------
$lte-sidebar-width:               var(--lte-sidebar-width) !default;
$lte-sidebar-padding-x:           .5rem !default;
$lte-sidebar-padding-y:           .5rem !default;
$lte-sidebar-custom-height:       4rem !default;
$lte-sidebar-custom-height-lg:    6rem !default;
$lte-sidebar-custom-height-xl:    8rem !default;
$lte-sidebar-custom-padding-x:    .85rem !default;
$lte-sidebar-custom-padding-y:    .5rem !default;
$lte-sidebar-transition:          min-width $lte-transition-speed $lte-transition-fn,
  max-width $lte-transition-speed $lte-transition-fn,
  margin-left $lte-transition-speed $lte-transition-fn,
  margin-right $lte-transition-speed $lte-transition-fn;

// margin-top $lte-transition-speed $lte-transition-fn,
// transform $lte-transition-speed $lte-transition-fn;

// SIDEBAR SKINS
// --------------------------------------------------------

// Dark sidebar
$lte-sidebar-dark-bg:                   var(--lte-sidebar-dark-bg) !default;
$lte-sidebar-dark-hover-bg:             var(--lte-sidebar-dark-hover-bg) !default;
$lte-sidebar-dark-color:                var(--lte-sidebar-dark-color) !default;
$lte-sidebar-dark-hover-color:          var(--lte-sidebar-dark-hover-color) !default;
$lte-sidebar-dark-active-color:         var(--lte-sidebar-dark-active-color) !default;
$lte-sidebar-dark-submenu-bg:           var(--lte-sidebar-dark-submenu-bg) !default;
$lte-sidebar-dark-submenu-color:        var(--lte-sidebar-dark-submenu-color) !default;
$lte-sidebar-dark-submenu-hover-color:  var(--lte-sidebar-dark-submenu-hover-color) !default;
$lte-sidebar-dark-submenu-hover-bg:     var(--lte-sidebar-dark-submenu-hover-bg) !default;
$lte-sidebar-dark-submenu-active-color: var(--lte-sidebar-dark-submenu-active-color) !default;
$lte-sidebar-dark-submenu-active-bg:    var(--lte-sidebar-dark-submenu-active-bg) !default;
$lte-sidebar-dark-header-color:         var(--lte-sidebar-dark-header-color) !default;

// Light sidebar
$lte-sidebar-light-bg:                   var(--lte-sidebar-light-bg) !default;
$lte-sidebar-light-hover-bg:             var(--lte-sidebar-light-hover-bg) !default;
$lte-sidebar-light-color:                var(--lte-sidebar-light-color) !default;
$lte-sidebar-light-hover-color:          var(--lte-sidebar-light-hover-color) !default;
$lte-sidebar-light-active-color:         var(--lte-sidebar-light-active-color) !default;
$lte-sidebar-light-submenu-bg:           var(--lte-sidebar-light-submenu-bg) !default;
$lte-sidebar-light-submenu-color:        var(--lte-sidebar-light-submenu-color) !default;
$lte-sidebar-light-submenu-hover-color:  var(--lte-sidebar-light-submenu-hover-color) !default;
$lte-sidebar-light-submenu-hover-bg:     var(--lte-sidebar-light-submenu-hover-bg) !default;
$lte-sidebar-light-submenu-active-color: var(--lte-sidebar-light-submenu-active-color) !default;
$lte-sidebar-light-submenu-active-bg:    var(--lte-sidebar-light-submenu-active-bg) !default;
$lte-sidebar-light-header-color:         var(--lte-sidebar-light-header-color) !default;

// SIDEBAR MINI
// --------------------------------------------------------
$lte-sidebar-mini-width:          ($nav-link-padding-x + $lte-sidebar-padding-x + .8rem) * 2 !default;
$lte-sidebar-nav-icon-width:      $lte-sidebar-mini-width - (($lte-sidebar-padding-x + $nav-link-padding-x) * 2) !default;
$lte-sidebar-user-image-width:    $lte-sidebar-nav-icon-width + ($nav-link-padding-x * .5) !default;

// SIDEBAR HORIZONTAL
// --------------------------------------------------------
$lte-sidebar-horizontal-width:    100%;
$lte-sidebar-horizontal-height:   80px !default;

// CONTROL SIDEBAR
// --------------------------------------------------------
$lte-control-sidebar-width:       $lte-sidebar-width !default;

// MAIN HEADER
// --------------------------------------------------------
$lte-main-header-bottom-border-width:  $border-width !default;
$lte-main-header-bottom-border-color:  $gray-300 !default;
$lte-main-header-bottom-border:        $lte-main-header-bottom-border-width solid $lte-main-header-bottom-border-color !default;
$lte-main-header-link-padding-y:       $navbar-padding-y !default;
$lte-main-header-height-inner:         ($nav-link-height + ($lte-main-header-link-padding-y * 2)) !default;
$lte-main-header-height:               add($lte-main-header-height-inner, $lte-main-header-bottom-border-width) !default;
$lte-nav-link-sm-padding-y:            .35rem !default;
$lte-nav-link-sm-height:               ($font-size-sm * $line-height-sm + $lte-nav-link-sm-padding-y * 1.785) !default;
$lte-main-header-height-sm-inner:      ($lte-nav-link-sm-height + ($lte-main-header-link-padding-y * 2)) !default;
$lte-main-header-height-sm:            add($lte-main-header-height-sm-inner, $lte-main-header-bottom-border-width) !default;

// HEADER SKINS
// --------------------------------------------------------

// Dark sidebar
$lte-header-dark-bg:              $dark;
$lte-header-dark-color:           $white;
$lte-header-mobile-dark-bg:       $dark;
$lte-header-mobile-dark-color:    $white;

// Light sidebar
$lte-header-light-bg:             $white;
$lte-header-light-color:          $dark;
$lte-header-mobile-light-bg:      $light;
$lte-header-mobile-light-color:   $dark;

// Content padding
$lte-content-padding-y:           0 !default;
$lte-content-padding-x:           .5rem !default;

// IMAGE SIZES
// --------------------------------------------------------
$lte-img-size-sm: 1.875rem !default;
$lte-img-size-md: 3.75rem !default;
$lte-img-size-lg: 6.25rem !default;
$lte-img-size-push: .625rem !default;

// MAIN FOOTER
// --------------------------------------------------------
$lte-main-footer-padding:           1rem !default;
$lte-main-footer-padding-sm:        $lte-main-footer-padding * .812 !default;
$lte-main-footer-border-top-width:  1px !default;
$lte-main-footer-border-top-color:  $gray-300 !default;
$lte-main-footer-border-top:        $lte-main-footer-border-top-width solid $lte-main-footer-border-top-color !default;
$lte-main-footer-height-inner:      (($lte-font-size-root * $line-height-base) + ($lte-main-footer-padding * 2)) !default;
$lte-main-footer-height:            add($lte-main-footer-height-inner, $lte-main-footer-border-top-width) !default;
$lte-main-footer-height-sm-inner:   (($font-size-sm * $line-height-base) + ($lte-main-footer-padding-sm * 2)) !default;
$lte-main-footer-height-sm:         add($lte-main-footer-height-sm-inner, $lte-main-footer-border-top-width) !default;
$lte-main-footer-bg:                $white !default;
$lte-main-footer-color:             tint-color($gray-700, 25%) !default;

// BRAND LINK
// --------------------------------------------------------
$lte-brand-link-padding-y:        $navbar-brand-padding-y + $navbar-padding-y;
$lte-brand-link-border-buttom:    1px;


// Cards
// --------------------------------------------------------
$lte-card-dark-border-color: tint-color($gray-900, 10%) !default;
$lte-card-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2) !default;
$lte-card-title-font-size: 1.1rem !default;
$lte-card-title-font-size-sm: 1rem !default;
$lte-card-title-font-weight: $font-weight-normal !default;
$lte-card-nav-link-padding-sm-y: .4rem !default;
$lte-card-nav-link-padding-sm-x: .8rem !default;
$lte-card-img-size: $lte-img-size-sm !default;

// PROGRESS BARS
// --------------------------------------------------------
$lte-progress-bar-border-radius: 1px !default;

// DIRECT CHAT
// --------------------------------------------------------
$lte-direct-chat-default-msg-bg: $lte-gray-x-light !default;
$lte-direct-chat-default-font-color: #444 !default;
$lte-direct-chat-default-msg-border-color: $lte-gray-x-light !default;

// Z-INDEX
// --------------------------------------------------------
$lte-zindex-main-header:          $zindex-fixed + 4 !default;
$lte-zindex-sidebar:              $zindex-fixed + 8 !default;
$lte-zindex-main-footer:          $zindex-fixed + 2 !default;
$lte-zindex-sidebar-horizontal:   $lte-zindex-main-header - 2 !default;
$lte-zindex-control-sidebar:      $zindex-fixed + 1 !default;
$lte-zindex-toasts:               $lte-zindex-sidebar + 2 !default;
$lte-zindex-content-wrapper:      $lte-zindex-sidebar - 2 !default;
$lte-zindex-preloader:            $lte-zindex-toasts + 2 !default;

// Body background (Affects main content background only)
$lte-main-bg:                   #f4f6f9 !default;
$lte-main-color:                  $black !default;

// BUTTON
// --------------------------------------------------------
$lte-button-default-background-color: $gray-100 !default;
$lte-button-default-color: #444 !default;
$lte-button-default-border-color: #ddd !default;

$lte-button-padding-y-xs: .125rem !default;
$lte-button-padding-x-xs: .25rem !default;
$lte-button-line-height-xs: $line-height-sm !default;
$lte-button-font-size-xs: ($font-size-base * .75) !default;
$lte-button-border-radius-xs: .15rem !default;

// RIBBON
// --------------------------------------------------------
$lte-ribbon-border-size: 3px !default;
$lte-ribbon-line-height: 100% !default;
$lte-ribbon-padding: .375rem 0 !default;
$lte-ribbon-font-size: .8rem !default;
$lte-ribbon-width: 90px !default;
$lte-ribbon-wrapper-size: 70px !default;
$lte-ribbon-top: 10px !default;
$lte-ribbon-right: -2px !default;
$lte-ribbon-lg-wrapper-size: 120px !default;
$lte-ribbon-lg-width: 160px !default;
$lte-ribbon-lg-top: 26px !default;
$lte-ribbon-lg-right: 0 !default;
$lte-ribbon-xl-wrapper-size: 180px !default;
$lte-ribbon-xl-width: 240px !default;
$lte-ribbon-xl-top: 47px !default;
$lte-ribbon-xl-right: 4px !default;
