//
// Core: Sidebar Mini
//

// A fix for text overflow while transitioning from sidebar mini to full sidebar
.nav-sidebar,
.nav-sidebar > .nav-header,
.nav-sidebar .nav-link {
  white-space: nowrap;
}

.sidebar-collapse:not(.sidebar-horizontal) {
  .main-sidebar {
    min-width: $lte-sidebar-mini-width;
    max-width: $lte-sidebar-mini-width;
  }

  // Make the sidebar headers
  .nav-sidebar .nav-header {
    display: none;
  }

  .nav-sidebar {
    .nav-link {
      width: $lte-sidebar-mini-width - $lte-sidebar-padding-x * 2;
    }
  }

  .nav-sidebar .nav-link p {
    width: 0;
    white-space: nowrap;
  }

  .nav-sidebar .nav-link p,
  .brand-link {
    margin-left: -10px;
    visibility: hidden;
    animation-name: fadeOut;
    animation-duration: $lte-transition-speed;
    animation-fill-mode: both;
  }

  &.sidebar-is-hover {
    .main-sidebar {
      min-width: $lte-sidebar-width;
      max-width: $lte-sidebar-width;
    }

    .nav-sidebar .nav-header {
      display: inline-block;
    }

    .nav-sidebar .nav-link {
      width: subtract($lte-sidebar-width, $lte-sidebar-padding-x * 2);
    }

    .nav-sidebar .nav-link p,
    .brand-link {
      margin-left: 0;
      visibility: visible;
      animation-name: fadeIn;
      animation-duration: $lte-transition-speed;
      animation-fill-mode: both;
    }
  }
}

// .sidebar-mini.sidebar-collapse:not(.layout-fixed):not(.sidebar-horizontal) {
//   .sidebar {
//     overflow: visible;

//     .nav-sidebar .nav-item:hover {
//       position: relative;

//       .nav-link {
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//       }

//       span {
//         animation: none;
//         z-index: $lte-zindex-sidebar + 12;
//         visibility: visible;
//         display: inline-block;
//         position: absolute;
//         width: $lte-sidebar-width;
//         left: $lte-sidebar-mini-width;
//         top: 0;
//         background-color: inherit;
//         padding: inherit;
//         padding-left: 1rem;
//         margin-left: -1rem;
//         border-top-left-radius: 0;
//         border-bottom-left-radius: 0;
//       }
//     }
//   }
// }

// .sidebar-is-opening {
//   .nav-sidebar .nav-link p,
//   .brand-link {
//     margin-left: 0;
//     animation-name: fadeIn;
//     animation-duration: $lte-transition-speed;
//     animation-fill-mode: both;
//     visibility: visible;
//   }
// }

// .sidebar-is-collapsing {
//   .nav-sidebar .nav-link p,
//   .brand-link {
//     margin-left: -10px;
//     animation-name: fadeOut;
//     animation-duration: $lte-transition-speed;
//     animation-fill-mode: both;
//     visibility: visible;
//   }
// }
