//
// Mixins: Sidebar Variant
//

@mixin header-variant(
  $lte-header-bg,
  $lte-header-color,
  $lte-header-mobile-bg,
  $lte-header-mobile-color
) {
  .main-header {
    color: $lte-header-color;
    background-color: $lte-header-bg;

    .nav-link {
      color: $dark;

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .main-header {
      color: $lte-header-mobile-color;
      background-color: $lte-header-mobile-bg;
    }
  }
}
