//
// Component: Users List
//

.users-list {
  @include list-unstyled ();

  > li {
    float: left;
    width: 25%;
    padding: 10px;
    text-align: center;

    img {
      @include border-radius(50%);
      max-width: 100%;
      height: auto;
    }

    > a:hover {
      &,
      .users-list-name {
        color: #999;
      }
    }
  }
}

.users-list-name,
.users-list-date {
  display: block;
}

.users-list-name {
  overflow: hidden;
  font-size: $font-size-sm;
  color: $gray-700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-list-date {
  font-size: 12px;
  color: shade-color($gray-500, 20%);
}
