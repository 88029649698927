//
// Misc: Miscellaneous
//

.border-transparent {
  border-color: transparent !important;
}

// Description Blocks
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;

  &.margin-bottom {
    margin-bottom: 25px;
  }

  > .description-header {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  > .description-text {
    text-transform: uppercase;
  }

  // Description Block Extension
  .description-icon {
    font-size: 16px;
  }
}

// List utility classes
.list-group-unbordered {
  > .list-group-item {
    padding-right: 0;
    padding-left: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
}

.list-header {
  padding: 10px 4px;
  font-size: 15px;
  font-weight: 700;
  color: $gray-600;
}

.list-seperator {
  height: 1px;
  margin: 15px 0 9px;
  background-color: $card-border-color;
}

.list-link {
  > a {
    padding: 4px;
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }
}

// User block
.user-block {
  float: left;

  img {
    float: left;
    width: 40px;
    height: 40px;
  }

  .username,
  .description,
  .comment {
    display: block;
    margin-left: 50px;
  }

  .username {
    margin-top: -1px;
    font-size: 16px;
    font-weight: 600;
  }

  .description {
    margin-top: -3px;
    font-size: 13px;
    color: $gray-600;
  }

  &.user-block-sm {
    img {
      width: $lte-img-size-sm;
      height: $lte-img-size-sm;
    }

    .username,
    .description,
    .comment {
      margin-left: 40px;
    }

    .username {
      font-size: 14px;
    }
  }
}

// Image sizes
.img-sm,
.img-md,
.img-lg {
  float: left;
}

.img-sm {
  width: $lte-img-size-sm;
  height: $lte-img-size-sm;

  + .img-push {
    margin-left: $lte-img-size-sm + $lte-img-size-push;
  }
}

.img-md {
  width: $lte-img-size-md;
  height: $lte-img-size-md;

  + .img-push {
    margin-left: $lte-img-size-md + $lte-img-size-push;
  }
}

.img-lg {
  width: $lte-img-size-lg;
  height: $lte-img-size-lg;

  + .img-push {
    margin-left: $lte-img-size-lg + $lte-img-size-push;
  }
}

// Image bordered
.img-bordered {
  padding: 3px;
  border: 3px solid $gray-500;
}

.img-bordered-sm {
  padding: 2px;
  border: 2px solid $gray-500;
}

// Rounded and Circle Images
.img-rounded {
  @include border-radius($border-radius);
}

.img-circle {
  @include border-radius(50%);
}

// Image sizes
.img-size-64,
.img-size-50,
.img-size-32 {
  height: auto;
}

.img-size-64 {
  width: 64px;
}

.img-size-50 {
  width: 50px;
}

.img-size-32 {
  width: 32px;
}

// Block sizes
.size-32,
.size-40,
.size-50 {
  display: block;
  text-align: center;
}

.size-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.size-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.size-50 {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

// General attachemnt block
.attachment-block {
  padding: 5px;
  margin-bottom: 10px;
  background-color: $gray-100;
  border: 1px solid $card-border-color;

  .attachment-img {
    float: left;
    max-width: 100px;
    height: auto;
    max-height: 100px;
  }

  .attachment-pushed {
    margin-left: 110px;
  }

  .attachment-heading {
    margin: 0;
  }

  .attachment-text {
    color: $gray-700;
  }
}

// Overlays for Card, InfoBox & SmallBox
.card,
.overlay-wrapper,
.info-box,
.small-box {
  // Box overlay for LOADING STATE effect
  > .overlay,
  > .loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    @include border-radius($border-radius);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($white, .7);

    > i,
    > svg,
    > img,
    > object,
    > iframe {
      color: $gray-800;
    }

    &.dark {
      background-color: rgba($black, .5);

      > i,
      > svg,
      > img,
      > object,
      > iframe {
        color: $gray-400;
      }
    }
  }
}

.tab-pane {
  // Box overlay for LOADING STATE effect on Tab Panels
  > .overlay-wrapper {
    position: relative;
    > .overlay {
      flex-direction: column;
      width: add(100%, (2 * $card-spacer-x));
      height: add(100%, (2 * $card-spacer-x));
      margin-top: -$card-spacer-x;
      margin-left: -$card-spacer-x;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      &.dark {
        color: $white;
      }
    }
  }
}

// Ribbon
.ribbon-wrapper {
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 10;
  width: $lte-ribbon-wrapper-size;
  height: $lte-ribbon-wrapper-size;
  overflow: hidden;

  &.ribbon-lg {
    width: $lte-ribbon-lg-wrapper-size;
    height: $lte-ribbon-lg-wrapper-size;

    .ribbon {
      top: $lte-ribbon-lg-top;
      right: $lte-ribbon-lg-right;
      width: $lte-ribbon-lg-width;
    }
  }

  &.ribbon-xl {
    width: $lte-ribbon-xl-wrapper-size;
    height: $lte-ribbon-xl-wrapper-size;

    .ribbon {
      top: $lte-ribbon-xl-top;
      right: $lte-ribbon-xl-right;
      width: $lte-ribbon-xl-width;
    }
  }

  .ribbon {
    position: relative;
    top: $lte-ribbon-top;
    right: $lte-ribbon-right;
    width: $lte-ribbon-width;
    padding: $lte-ribbon-padding;
    font-size: $lte-ribbon-font-size;
    line-height: $lte-ribbon-line-height;
    text-align: center;
    text-shadow: 0 -1px 0 rgba($black, .4);
    text-transform: uppercase;
    box-shadow: 0 0 $lte-ribbon-border-size rgba($black, .3);
    transform: rotate(45deg);

    &::before,
    &::after {
      position: absolute;
      bottom: -$lte-ribbon-border-size;
      content: "";
      border-top: $lte-ribbon-border-size solid #9e9e9e;
      border-right: $lte-ribbon-border-size solid transparent;
      border-left: $lte-ribbon-border-size solid transparent;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}

// Scroll To Top
.back-to-top {
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  z-index: $lte-zindex-control-sidebar + 1;

  &:focus {
    box-shadow: none;
  }
}

// Pre
pre {
  padding: .75rem;
}

// Blockquotes styles
blockquote {
  padding: .5em .7rem;
  margin: 1.5em .7rem;
  background-color: $white;
  border-left: .7rem solid $primary;

  .box & {
    background-color: $gray-200;
  }

  p:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.25rem;
    font-weight: 600;
    color: $primary;
  }

  @each $color, $value in $theme-colors {
    &.quote-#{$color} {
      border-color: $value;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $value;
      }
    }
  }

  @each $color, $value in $colors {
    &.quote-#{$color} {
      border-color: $value;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $value;
      }
    }
  }
}


// Tab Custom Content

.tab-custom-content {
  padding-top: .5rem;
  margin-top: .5rem;
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
}

.nav + .tab-custom-content {
  padding-bottom: .5rem;
  margin-top: 0;
  margin-bottom: .5rem;
  border-top: none;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}


// Badge BTN Style
.badge-btn {
  padding: $lte-button-padding-y-xs * 2 $lte-button-padding-x-xs * 2;
  font-size: $lte-button-font-size-xs;
  font-weight: 400;
  border-radius: $lte-button-border-radius-xs;
}

.badge-btn.badge-pill {
  padding: .375rem .6rem;
}

