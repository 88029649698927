//
// Core: Main Header
//

@include header-variant(
  $lte-header-light-bg,
  $lte-header-light-color,
  $lte-header-mobile-light-bg,
  $lte-header-mobile-light-color
);

.main-header {
  z-index: $lte-zindex-main-header;
  grid-area: main-header;
  border-bottom: $lte-main-header-bottom-border;

  .nav-link {
    position: relative;
    height: $nav-link-height;
  }
}

// Navbar badge
.navbar-badge {
  position: absolute;
  top: 9px;
  right: 5px;
  padding: 2px 4px;
  font-size: .6rem;
  font-weight: 300;
}

.layout-navbar-fixed {
  .main-header {
    // position: fixed;
  }

  .content-wrapper {
    // margin-top: $lte-main-header-height;
  }
}
