@mixin nav-treeview-light(
  $lte-sidebar-bg,
  $lte-sidebar-hover-bg,
  $lte-sidebar-color,
  $lte-sidebar-hover-color,
  $lte-sidebar-active-color,
  $lte-sidebar-submenu-bg,
  $lte-sidebar-submenu-color,
  $lte-sidebar-submenu-hover-color,
  $lte-sidebar-submenu-hover-bg,
  $lte-sidebar-submenu-active-color,
  $lte-sidebar-submenu-active-bg,
  $lte-sidebar-header-color
) {
  // Sidebar background color
  background-color: $lte-sidebar-bg;

  // Sidebar Menu. First level links
  .nav-sidebar > .nav-item {
    // links
    > .nav-link {
      // border-left: 3px solid transparent;
      &:active,
      &:focus {
        color: $lte-sidebar-color;
      }
    }

    // Hover and active states
    &.menu-open > .nav-link,
    &:hover > .nav-link {
      color: $lte-sidebar-hover-color;
      background-color: $lte-sidebar-hover-bg;
    }

    > .nav-link.active {
      color: $lte-sidebar-active-color;
    }

    // First Level Submenu
    > .nav-treeview {
      background-color: $lte-sidebar-submenu-bg;
    }
  }

  // Section Heading
  .nav-header {
    color: $lte-sidebar-header-color;
    background-color: inherit;
  }

  // All links within the sidebar menu
  .sidebar {
    a {
      color: $lte-sidebar-color;

      &:hover {
        text-decoration: none;
      }
    }
  }

  // All submenus
  .nav-treeview {
    > .nav-item {
      > .nav-link {
        color: $lte-sidebar-submenu-color;

        &:hover,
        &:focus {
          color: $lte-sidebar-submenu-hover-color;
          background-color: $lte-sidebar-submenu-hover-bg;
        }
      }

      > .nav-link.active {
        &,
        &:hover {
          color: $lte-sidebar-submenu-active-color;
          background-color: $lte-sidebar-submenu-active-bg;
        }
      }

      > .nav-link:hover {
        background-color: $lte-sidebar-submenu-hover-bg;
      }
    }
  }
}
